<template>
  <div
    class="box is-paddingless is-relative"
    :class="{ 'is-alert': icons.alert }"
  >
    <a
      v-if="icons.alert"
      class="ribbon"
      v-tippy="{ placement: 'left', trigger: 'click' }"
      title="Action required"
    >
      <span class="icon is-small has-text-white">
        <i class="fas fa-exclamation-triangle is-size-7" />
      </span>
    </a>
    <div class="p-5">
      <div class="columns is-mobile is-centered is-vcentered">
        <div class="column is-narrow">
          <button
            v-if="listing.vrm"
            class="button is-size-7"
            :class="{ 'is-loading': $wait.is(`companion-${listing.vrm}`) }"
            :disabled="!isCompanionUser || $wait.is('companion-*')"
            @click="goToCompanion"
          >
            {{ listing.vrm }}
          </button>
        </div>
        <div class="column">
          <p v-if="description">
            {{ description.truncated || description.full }}
          </p>
          <p class="has-text-grey-light" v-else>
            N/A
          </p>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-narrow">
          <p class="label is-size-7">Mileage</p>
          <span v-if="listing.mileage">
            <span>{{ formatNumber(listing.mileage) }}</span>
          </span>
          <span class="has-text-grey-light" v-else>
            N/A
          </span>
        </div>
        <div class="column is-narrow">
          <p class="label is-size-7">Listing price</p>
          <div :class="icons.price" class="tag is-rounded has-text-weight-bold">
            {{ pounds(listing.price) }}
          </div>
        </div>
        <div class="column is-narrow">
          <p class="label is-size-7">Optimal price</p>
          <div
            class="tag is-success is-rounded has-text-white has-text-weight-bold"
            v-if="listing.pricePosition"
          >
            <span v-if="!priceInfo.isPricedWell">
              <span>
                {{ pounds(listing.suggestedPrice) }}
              </span>
            </span>
            <span v-else class="icon">
              <i class="fas fa-check" />
            </span>
          </div>
          <span class="has-text-grey-light" v-else>
            N/A
          </span>
        </div>
      </div>
      <div v-if="!isHidden">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            <p class="label is-size-7">Days in stock</p>
            <span v-if="listing.daysInStock">
              <span>
                {{ listing.daysInStock }}
              </span>
              <span class="icon is-small" v-if="icons.daysInStock">
                <i :class="icons.daysInStock" />
              </span>
            </span>
            <span class="has-text-grey-light" v-else>
              N/A
            </span>
          </div>
          <div class="column is-narrow">
            <p class="label is-size-7">Vehicle status</p>
            <span v-if="listing.vehicleStatus" class="is-capitalized">
              {{ listing.vehicleStatus }}
            </span>
            <span class="has-text-grey-light" v-else>
              N/A
            </span>
          </div>
          <div class="column is-narrow">
            <p class="label is-size-7">Market demand</p>
            <DesirabilityBar
              v-bind="{ number: true, desirability: listing.desirability }"
              v-if="listing.desirability"
              class="is-flex"
            />
            <span class="has-text-grey-light" v-else>
              N/A
            </span>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-narrow">
            <p class="label is-size-7">Price position</p>
            <span v-if="listing.percentageValue">
              <span>{{ Math.round(listing.percentageValue) }}%</span>
              <a
                class="icon is-small"
                :title="priceInfo.pricePositionTooltip"
                v-tippy
              >
                <i :class="icons.pricePosition" />
              </a>
            </span>
            <span class="has-text-grey-light" v-else>
              N/A
            </span>
          </div>
          <div class="column is-narrow">
            <p class="label is-size-7">Last price change</p>
            <a
              class="has-text-info"
              @click="openPriceChangeModal"
              v-if="priceInfo.priceChange"
            >
              <u>
                {{ priceInfo.priceChangeText }}
              </u>
            </a>
            <span
              class="has-text-grey-light"
              v-else-if="
                listing.daysSinceLastPriceChange ||
                  listing.daysSinceLastPriceChange === 0
              "
            >
              N/A
            </span>
            <span class="has-text-grey-light" v-else>
              N/A
            </span>
            <span class="icon is-small" v-if="icons.lastPriceChange">
              <i :class="icons.lastPriceChange" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <button
      class="button is-small is-info is-light is-fullwidth"
      data-test="stockview-stock__show-more"
      @click="toggleCard"
    >
      <span v-if="isHidden">
        Show more
      </span>
      <span v-else>
        Show less
      </span>
    </button>
  </div>
</template>

<script>
import { pounds } from 'core/utils/format'
import { formatNumber } from 'accounting'
import { mapMutations, mapActions } from 'vuex'
import * as listing from '../services/stockview-listing.service'
import * as companion from 'modules/companion/services'
export default {
  name: 'StockviewListingAccordionCard',
  components: {
    DesirabilityBar: () =>
      import('core/modules/desirability-bar/components/DesirabilityBar')
  },
  props: {
    listing: {
      type: Object,
      required: true
    },
    isCompanionUser: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    isHidden: true
  }),
  computed: {
    description() {
      return listing.getDescription({ listing: this.listing, maxLength: 23 })
    },
    icons() {
      return listing.getIcons(this.listing)
    },
    priceInfo() {
      return listing.getPriceInfo(this.listing)
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['getListings']),
    ...mapMutations('stockview/listings', ['setFilter']),
    pounds,
    formatNumber,
    openPriceChangeModal() {
      listing.openPriceChangeModal(this.listing)
    },
    async goToCompanion() {
      const { vrm, mileage } = this.listing
      if (!this.isCompanionUser) return
      try {
        this.$wait.start(`companion-${vrm}`)
        await companion.value({ vrm, mileage })
        this.$mxp.track('stockview_stock_companion_lookup')
      } finally {
        this.$wait.end(`companion-${vrm}`)
      }
    },
    toggleCard() {
      this.isHidden = !this.isHidden
    }
  }
}
</script>

<style lang="sass" scoped>
.box
  &.is-alert
    border-top: .25rem solid $danger
.ribbon
  position: absolute
  right: .6rem
  top: .5rem
  width: 1.8rem
  height: 1.5rem
  &:before,
  &:after
    content: ''
    position: absolute
    border-top: .4rem solid $danger
    border-bottom: .7rem solid $danger
    border-left: .9rem solid $danger
    border-right: .9rem solid $danger
  &:before
    bottom: 1rem
  &:after
    border-bottom-color: transparent
    bottom: .1rem
  .icon
    position: absolute
    top: -.425rem
    z-index: 1
    right: 0
    left: 0
    margin: auto
[data-test='stockview-stock__show-more']
  border-top-left-radius: unset
  border-top-right-radius: unset
</style>
